<template>
	<v-footer padless color="darkblue white--text">
	
		<v-container appwidth>
	
			<v-row no-gutters class="text-left">
				<v-col cols="12" sm="12">
					<v-card-text class="pb-12 pt-12">
						{{ new Date().getFullYear() }} — <strong>Peter Schöll</strong> |
						<a href="https://www.linkedin.com/in/peter-schoell/"><v-icon color="white">mdi-linkedin</v-icon></a> |
						<a href="https://www.xing.com/profile/Peter_Schoell/"><v-icon color="white">mdi-xing</v-icon></a> |
						<v-btn text color="primary" to="/impressum/">Impressum</v-btn>
						<v-btn text color="primary" to="/datenschutzerklaerung/" >Datenschutzerklaerung</v-btn>
						<v-btn text color="primary" @click="showCookieCompDialog()"  >Cookies</v-btn>
					</v-card-text>
				</v-col>
			</v-row>
			

		  </v-container>
	</v-footer>
</template>


<script>

import { EventBus } from '@/bus/bus.js';


export default {

  methods: {

		showCookieCompDialog () {
			EventBus.$emit('showCookieCompDialog');
		}
  	
	},
};

</script>


<style>

	footer a {
		text-decoration: none;
	}

</style>


