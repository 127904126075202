<template>
  <div>
    <div>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="12"
        >
          <p>Wenn Sie eine Website besuchen, kann diese Informationen auf Ihrem Browser speichern oder abrufen, meist in Form von Cookies. Diese Informationen können sich auf Sie, Ihre Präferenzen oder Ihr Gerät beziehen und werden meist dazu verwendet, die Webseite so zu gestalten, wie Sie es erwarten. Die Informationen identifizieren Sie normalerweise nicht direkt, aber sie können Ihnen eine persönlichere Web-Erfahrung ermöglichen.</p>
          <p>Da wir Ihr Recht auf Privatsphäre respektieren, können Sie sich dafür entscheiden, bestimmte Arten von Cookies nicht zuzulassen. Unter den verschiedenen Kategorieüberschriften finden Sie die jeweiligen Informationen und Einstellmöglichkeiten der einzelnen Cookies. Das Blockieren einiger Arten von Cookies kann jedoch Ihre Erfahrung mit der Webseite/Web-Anwendung und den von uns angebotenen Dienstleistungen beeinträchtigen.</p>
        </v-col>
      </v-row>
    </div>

    <div>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="2"
        >
          <!--<h2 class="title">Alle Cookies</h2>-->
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="8"
          lg="10"
        >

          <v-btn
            id="acceptAllCookies"
            color="secondary"
            v-on:click="acceptAll()"
            class="mt-3 mr-3"
          >
            Alle akzeptieren
          </v-btn>

          <!--<v-btn
            id="declineAllCookies"
            v-if="allCookiesStatusIntern"
            color="primary"
            v-on:click="declineAll()"
          >
            Alle ablehnen
          </v-btn>-->

          <v-btn
            id="declineAllCookies"
            v-on:click="declineAll()"
            color="secondary"
            outlined
            class="mt-3"
          >
            Nur notwendige akzeptieren
          </v-btn>

        </v-col>

      </v-row>
    </div>

    <div
      class="mt-10"
      v-for="purposeNode in this.allPurposes"
      :key="purposeNode.id"
      v-if="getRelatedCookies(purposeNode.id).length>0"
    >
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="2"
        >
          <h2
            class="title"
            v-html="purposeNode.name"
          />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="8"
          lg="10"
        >

          <p v-html="purposeNode.description" />
          <p
            class="caption"
            v-html="purposeNode.descriptionlegal"
          />
          <p class="title">Verwendete Cookies:</p>

          <v-expansion-panels
            flat
            class="subtitle-2"
          >
            <v-expansion-panel
              class="grey lighten-4"
              v-for="cookie in getRelatedCookies(purposeNode.id)"
              :key="cookie.id"
            >

              <v-expansion-panel-header class="py-0">
                <v-checkbox
                  color="primary"
                  v-on:click.stop 
                  v-model="cookiesStatus"
                  :disabled="!purposeNode.optin"
                  :readonly="!purposeNode.optin"
                  :value="cookie.name"
                />{{cookie.name}}
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-simple-table>
                  <tbody>
                    <tr>
                    </tr>
                    <tr v-if=cookie.provider>
                      <td style="width: 20%">Anbieter:</td>
                      <td>{{cookie.provider}}</td>
                    </tr>
                    <tr v-if=cookie.purpose>
                      <td style="width: 20%">Zweck:</td>
                      <td>{{cookie.purpose}}</td>
                    </tr>
                    <tr v-if=cookie.privacypolicy>
                      <td style="width: 20%">Datenschutzerklärung:</td>
                      <td><a
                          target="_blank"
                          :href=cookie.privacypolicy
                        >{{cookie.privacypolicy}}</a></td>
                    </tr>
                    <tr v-if=cookie.cookiename>
                      <td style="width: 20%">Cookie Name:</td>
                      <td>{{cookie.cookiename}}</td>
                    </tr>
                    <tr v-if=cookie.runtime>
                      <td style="width: 20%">Cookie Laufzeit:</td>
                      <td>{{cookie.runtime}}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-checkbox
            color="primary"
            v-model="purposesStatus"
            :value="purposeNode.id"
            :disabled="!purposeNode.optin"
            :readonly="!purposeNode.optin"
            :label="'Alle akzeptieren'"
            :indeterminate="isIndeterminate(purposeNode.id)"
          ></v-checkbox>
        </v-col>

      </v-row>

    </div>
    <v-row>

      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="2"
      >
        <h2 class="title">Rechtliche Hinweise</h2>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="8"
        lg="10"
      >
        Erfahren Sie mehr über unsere Cookies-Handhabung in unserer <a
          href="/privacystatement"
          target="_blank"
        >Datenschutzrichtlinie</a>.
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-right">
        <v-btn
          id="cancelCookies"
          v-on:click="cancel()"
          outlined
          color="secondary"
          class="mt-3 mr-3"
        >
          Abbrechen
        </v-btn>
        <v-btn
          id="saveCookies"
          v-on:click="saveStatus()"
          color="secondary"
          class="mt-3 mr-3"
        >
          Speichern
        </v-btn>
      </v-col>
    </v-row>
  </div>

</template>




/************************************************/
/* SCRIPT */
/************************************************/


<script>


import { EventBus } from '@/bus/bus.js';


export default {

  data () {
    return {
      allCookiesStatusIntern: false,
      purposesStatusIntern: [],
      cookiesStatusIntern: [],

			allPurposes: 
				[
				{id:'1', 
					name:'Anzeigen oder Inhalte technisch bereitstellen', 
					optin:false,
					description:'Ihr Gerät kann Informationen empfangen und senden die notwendig sind, damit Sie Inhalte und Anzeigen sehen und nutzen können.',
					descriptionlegal:'Zur Bereitstellung von Informationsdaten und um auf technische Anfragen zu reagieren können Anbieter:<br/><ul><li>die IP-Adresse eines Benutzers verwenden, um über das Internet eine Anzeige einzublenden;</li><li> auf die Interaktion eines Benutzers mit einer Anzeige reagieren, indem sie den Benutzer zu einer Zielseite weiterleiten;</li><li>die IP-Adresse eines Benutzers verwenden, um Inhalte über das Internet bereitzustellen;</li><li>auf die Interaktion eines Benutzers mit Inhalten reagieren, indem sie den Benutzer zu einer Zielseite weiterleiten;</li><li>Informationen zum Gerätetyp und seinen Möglichkeiten zur Darstellung von Anzeigen oder Inhalten verwenden, z. B. um Werbemittel oder Videodateien in der richtigen Größe und in einem vom Gerät unterstützten Format bereitzustellen. Anbieter können nicht:Datenverarbeitungen durchführen, die in einem der anderen Verarbeitungszwecke definiert sind.</ul>'},
					
				{id:'2', 
					name:'Inhalte-Leistung messen', 
					optin:true,
					description:'Die Leistung und Wirksamkeit von Inhalten, die Sie sehen oder mit denen Sie interagieren, kann gemessen werden.',
					descriptionlegal:'Um die Leistung von Inhalten zu messen, können Anbieter:<br/><ul><li>messen und darüber berichten, wie Inhalte an Nutzer ausgeliefert wurden und wie diese mit ihnen interagiert haben;</li><li>Berichte über direkt messbare oder bereits bekannte Informationen von Nutzern, die mit den Inhalten interagiert haben, bereitstellen. Anbieter können nicht:messen, ob und wie Anzeigen (einschließlich nativer Anzeigen) an einen Benutzer ausgeliefert wurden und wie dieser mit ihnen interagiert hat;</li><li>Über Befragungspanels oder ähnliche Verfahren erhobene Daten über Zielgruppen zusammen mit Inhalts-Messungsdaten verwenden, ohne dafür eine eigene Rechtsgrundlage für den Einsatz von Marktforschung zur Gewinnung von Erkenntnissen über Zielgruppen zu haben.</li></ul>'},
					
					],
			allCookies: 
				[
				{id:'1', 
					name:'Google Analytics', 
					provider:'Google LLC', 
					purpose:'Cookie von Google für Website-Analysen. Erzeugt statistische Daten darüber, wie der Besucher die Website nutzt.', 
					privacypolicy: 'https://marketingplatform.google.com/about/analytics/terms/de/',
					cookiename: '_ga,_gat,_gid',
					runtime: '26 Monate',
					belongsTo: ['2'],
					},
				{id:'2', 
					name:'Google Tag Manager', 
					provider:'Google LLC', 
					purpose:'Google Tag Manager ist eine Lösung, mit der Vermarkter Website-Tags über eine Oberfläche verwalten können. Das Tool Tag Manager selbst (das die Tags implementiert) ist eine Cookie-lose Domain und erfasst keine personenbezogenen Daten.', 
					privacypolicy: 'https://www.google.com/intl/de/tagmanager/use-policy.html',
					cookiename: 'gtm',
					runtime: '2 Jahre',
					belongsTo: ['1'],
					},
					],					
					
					
		};
	},



  computed: {

    purposesStatus: {
      // getter
      get: function () {
        return this.purposesStatusIntern;
      },
      // setter
      set: function (purposesStatus) {
		  
        this.cookiesStatusIntern = [];
        for (var a = 0; a < purposesStatus.length; a++) {
          // set all related cookies
          var cookies = this.getRelatedCookies(purposesStatus[a]);
          for (var c = 0; c < cookies.length; c++) {
            if (this.cookiesStatusIntern.indexOf(cookies[c].name) === -1) {
              this.cookiesStatusIntern.push(cookies[c].name);
            }
          }
        }
		
		
		
        this.purposesStatusIntern = purposesStatus;



      }
    },

    cookiesStatus: {
      // getter
      get: function () {
        return this.cookiesStatusIntern;
      },
      // setter
      set: function (cookiesStatus) {
		  
        this.cookiesStatusIntern = cookiesStatus;
        this.checkStatus();
      }
    }
	
  },

  created: function () {
    // init values and read from localStorage
    this.init(true);
  },

  methods: {


    // add/remove perposes depending on th ecookie status
    checkStatus () {

      // remove purpose, if no cookie of purpose is set
      var purposesToRemove = [];
      var purposesToAdd = [];

      for (var p of this.allPurposes) {


        // set all related cookies
        var relatedCookies = this.getRelatedCookies(p.id);

        var oneOrMoreCookieSet = false;
        var allCookiesSet = true;

        for (var rc of relatedCookies) {
          if (this.cookiesStatusIntern.includes(rc.name)) {
            oneOrMoreCookieSet = true;
          }
          else {
            allCookiesSet = false;
          }
        }
		
        // none of the purposes cookies are set -> remove purpose
        if (!oneOrMoreCookieSet) {
          purposesToRemove.push(p.id);
        }

        if (allCookiesSet) {
          purposesToAdd.push(p.id);
        }
      }

      for (var p of purposesToRemove) {
        if (this.purposesStatus.includes(p)) {
          this.purposesStatus.splice(this.purposesStatus.indexOf(p), 1);
        }
      }


      for (var p of purposesToAdd) {
        if (!this.purposesStatus.includes(p)) {
          this.purposesStatus.push(p);
        }
      }




    },

    acceptAll: function () {

      for (var p of this.allPurposes) {
        for (var c of this.getRelatedCookies(p.id)) {
          if (!this.purposesStatusIntern.includes(p.id)) {
            this.purposesStatusIntern.push(p.id);
          }
          if (!this.cookiesStatusIntern.includes(c.name)) {
            this.cookiesStatusIntern.push(c.name);
          }
        }
      }
      this.allCookiesStatusIntern = true;
      this.saveStatus();

      this.emitCloseDialog();
    },

    declineAll: function () {

      this.init(false);
      this.allCookiesStatusIntern = false;
      this.saveStatus();

      this.emitCloseDialog();
    },


    /**
     * indetermine, wenn nicht alle cookies des purpose gesetzt sind.
     */
    isIndeterminate: function (purposeId) {
		
      if (this.purposesStatusIntern.includes(purposeId)) {
        var cookies = this.getRelatedCookies(purposeId);
		
        for (var cookie of cookies) {
          if (!this.cookiesStatusIntern.includes(cookie.name)) {
            return true;
          }
        }
      }
	  
	  return false;
    },
    /**
     * save the state to the localStorage
     */
    saveStatus: function () {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem('purposesStatus', JSON.stringify(this.purposesStatusIntern));
        localStorage.setItem('activatedCookies', JSON.stringify(this.cookiesStatusIntern));
        localStorage.setItem('allCookiesStatus', this.allCookiesStatusIntern ? 'true' : 'false');
		localStorage.setItem('vue-cookie-accept-decline-myPanel1', 'accept');
      }

      this.gtmPush();

      this.emitCloseDialog();
    },
	
	/**
	* Push the load event to GTM to activate the active cookies
	*/ 
	gtmPush() {
		
		console.log("gtmPush");
		// push active cookies as event
		for (var c = 0; c < this.cookiesStatusIntern.length; c++) {
			var eventName = "load-"+this.cookiesStatusIntern[c].replace(/ /g,'');
			console.log("   gtm.trackEvent: "+eventName);
			this.$gtm.trackEvent({event: eventName});
			
			
		}
	},
	
	
    /**
     * cancel the dialog and close it
     */
    cancel: function () {
      
	  this.emitCloseDialog();

      // show cookie banner if nothing is set  
      if (typeof localStorage !== 'undefined') {
		  
        if (localStorage.getItem('vue-cookie-accept-decline-myPanel1') == null) {
          //$("#myPanel1").show();xxx
		  EventBus.$emit('showCookieBanner');
        }
      }
    },
    /**
    * Set default necessaryCookies. Called from created (with true) and from declineAll(false)
    *
    */
    init: function (readFromStorage) {
      this.purposesStatusIntern = [];
      this.cookiesStatusIntern = [];

      if (readFromStorage) {

        // read cookies and purposes from storage
        var purposesStatusStr = null;
        var activatedCookiesStr = null;
        if (typeof localStorage !== 'undefined') {
          purposesStatusStr = localStorage.getItem('purposesStatus');
          activatedCookiesStr = localStorage.getItem('activatedCookies');
          this.allCookiesStatusIntern = (localStorage.getItem('allCookiesStatus') == 'true');
        }

        if (purposesStatusStr != null) {
          var activatedPurposes = JSON.parse(purposesStatusStr);
          this.purposesStatusIntern = activatedPurposes;
        }

        // read cookies from storage
        if (activatedCookiesStr != null) {
          this.cookiesStatusIntern = activatedCookies;
          var activatedCookies = JSON.parse(activatedCookiesStr);
          this.cookiesStatusIntern = activatedCookies;
        }
      }

      // always add the the necessary purposes and cookies
      for (var c = 0; c < this.allCookies.length; c++) {
        for (var t = 0; t < this.allCookies[c].belongsTo.length; t++) {
          if (!this.getPurpose(this.allCookies[c].belongsTo[t]).optin) {
			  
			  
            var necessaryPurposeId = this.allCookies[c].belongsTo[t];
			
            if (!this.purposesStatusIntern.includes(necessaryPurposeId)) {
              this.purposesStatusIntern.push(necessaryPurposeId);
            }
            var necessaryCookies = this.getRelatedCookies(necessaryPurposeId);
            for (var nc of necessaryCookies) {
              if (!this.cookiesStatusIntern.includes(nc.name)) {
                this.cookiesStatusIntern.push(nc.name);
              }
            }
          }
        }
      }
	  
    },
	
	/**
    * Get Purpose by id
    *
    * @param purposeId
    * @return {Array} purposes
    */
	getPurpose: function (purposeId) {

		for (var p of this.allPurposes) {
          if (p.id == purposeId) {
			  return p;
			}
		}
		
		return "";

	},
	
	/**
    * Get related cookies as list
    *
    * @param purposeId
    * @return {Array} relatedCookies
    */
    getRelatedCookies: function (purposeId) {
		
      var relatedCookies = [];
      for (var c = 0; c < this.allCookies.length; c++) {
        for (var t = 0; t < this.allCookies[c].belongsTo.length; t++) {
          if (this.allCookies[c].belongsTo[t] == purposeId) {
            relatedCookies.push(this.allCookies[c]);
            break;
          }
        }
      }
	  
      return relatedCookies;
    },

    emitCloseDialog: function () {
      this.$emit('closeDialog');
    }
  }

}
</script>



/************************************************/
/* STYLE */
/************************************************/

<style>
.v-expansion-panel-header__icon {
  margin-left: 5px !important;
}
.v-expansion-panel-header > :not(.v-expansion-panel-header__icon) {
  -webkit-box-flex: 1;
  flex: 0;
}

.filtericon {
  padding-bottom: 2px;
}
</style>