<template>

	<div>
	
	<v-container fluid grey lighten-3>
  	<v-container appwidth>
		
		<v-row class="py-md-16 black--text">		
			<v-col class="py-16" cols="12" sm="12" md="6" lg="6">
				
				<h1>Flesch-Index berechnen</h1>
				<h2 class="pt-5 primary--text">Kostenlos</h2>

				<v-btn class="mt-10" rounded large to="/berechnen/" color="primary" depressed>Jetzt berechnen</v-btn>
			</v-col>
			<v-col class="py-16" cols="12" sm="12" offset-md="1" md="5" lg="5">
				<v-img src="@/assets/img/woman.jpg" />

			</v-col>
		</v-row>
				
	</v-container>	
	</v-container>	
	
  	<v-container fluid white class="py-15" >
  	<v-container appwidth class="py-15" black--text>
		<v-row>		
			<v-col cols="12" sm="12" md="6" lg="6">

				<p class="text-md-h5">Eine Formel für die Lesbarkeit eines Textes liefert der sogenannte Flesch-Index, benannt nach seinem Erfinder Rudolf Flesch. Er ging davon aus, dass kürzere Wörter und kürzere Sätze verständlicher sind als lange.</p>
				<v-btn class="px-0" text to="/formel/" color="primary" depressed>Formel</v-btn>


			</v-col>
			<v-col cols="12" sm="12" md="6" lg="6">


				<p class="text-md-h5">Der Flesch-Index eines Textes ergibt sich aus der durchschnittlichen Silbenzahl pro Wort und der durchschnittlichen Satzlänge. Je höher der Flesch-Wert, desto verständlicher der Text.</p>
				<v-btn class="px-0" text to="/beispiele/" color="primary" depressed>Beispiele</v-btn>


			</v-col>
			
		</v-row>
				
	</v-container>
	</v-container>
	
	
	
	</div>
		
</template>

<script>



export default {
  
	name: 'Home',
	
	
	 head: {
		title: {
			inner: 'Fleschindex kostenlos berechnen',
			separator: '-',
			complement: 'Fleschindex'
		},
  
		link: [
			{
				rel: 'canonical',
				href: 'https://fleschindex.de/',
			},
			
		],
		meta: [
			{
				name: 'description',
				content: 'Hier können Sie den Flesch-Index (Lesbarkeitsindex) online berechnen - kostenlos, ohne Anmeldung.',
			},
			{
				name: 'keywords',
				content: 'Flesch-Index, Lesbarkeitsindex, Reading-Ease"',
			},
			{
				property: 'og:title',
				content: 'Home',
			},
			{
				property: 'og:description',
				content: 'Hier können Sie den Flesch-Index (Lesbarkeitsindex) online berechnen - kostenlos, ohne Anmeldung.',
			},
			{
				property: 'og:image',
				content: 'https://fleschindex.de/assets/img/fleschindex.jpg',
			},
			{
				property: 'og:url',
				content: 'https://fleschindex.de/',
			},
			{ 	
				name: 'google-site-verification', 
				content: 'yb51QJ4It2hYFdh9BgwFHxJZaW3GkbuMzmD8Qzhkq3A' 
			}, 
			
		]
	 }
}
  
  
</script>
