import Vue from 'vue'
import App from './App.vue'
import router from './router'

import 'vuetify/dist/vuetify.min.css'
import './assets/style.css'
import '@mdi/font/css/materialdesignicons.css'

import vuetify from './plugins/vuetify';
import VueGtm from 'vue-gtm';
import Ads from 'vue-google-adsense'

import store from  './store/store.js';

Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)

Vue.config.productionTip = false


// Head / Meta / Links
const VueHead = require('vue-head')
Vue.use(VueHead)

new Vue({
	router,
	store,
	vuetify,
	render: function (h) { return h(App) }
}).$mount('#app')


Vue.use(VueGtm, {
	id: 'G-6879G3EECE',
	defer: true, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
	enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
	debug: false, // Whether or not display console logs debugs (optional)
	loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional) 
});



router.afterEach((to, from) => {

	var activatedCookiesStr = localStorage.getItem('activatedCookies');
	if (activatedCookiesStr) {
		var activatedCookies = JSON.parse(activatedCookiesStr);
		for (var c = 0; c < activatedCookies.length; c++) {
			var eventName = "load-"+activatedCookies[c].replace(/ /g,'');
			Vue.prototype.$gtm.trackEvent({event: eventName});
		}
	}

});

