import Vue from 'vue';

import Vuetify from 'vuetify/lib'


Vue.use(Vuetify)


const vuetifyOpts = {
	  theme: {
		themes: {
		  dark: {
			primary: '#DE3C4B',
			secondary: '#546E7A',
			darkblue: '#23304F',
			background: '#ff0000'
		  },
		  light: {
			primary: '#DE3C4B',
			secondary: '#546E7A',
			darkblue: '#23304F',
			background: '#ff0000'
		  }
		},
		options: {
            customProperties: true
        },
		}
	}

export default new Vuetify(vuetifyOpts)



