<template>

	<div>
	
	    <v-navigation-drawer
			v-model="drawer"
			app
			right
			temporary
		>
		<v-list dense>
			<v-list-item link to="/">
				<v-list-item-action>
					<v-icon>mdi-home</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>HOME</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item link to="/berechnen">
				<v-list-item-action>
					<v-icon>mdi-cogs</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>BERECHNEN</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item link to="/formel">
				<v-list-item-action>
					<v-icon>mdi-flask-outline</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>FORMEL</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item link to="/beispiele">
				<v-list-item-action>
					<v-icon>mdi-book-open-outline</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>BEISPIELE</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			
		  </v-list>
    </v-navigation-drawer>



	<v-app-bar
		  app
		  light
		>
      	<v-toolbar-items>
				<v-btn to="/" text>Fleschindex</v-btn>
		</v-toolbar-items>
	  
	  	  		
		<v-spacer></v-spacer>
		 
		<v-toolbar-items class="hidden-sm-and-down">
			<v-btn to="/berechnen/" text>Berechnen</v-btn>
			<v-btn to="/beispiele/" text>Beispiele</v-btn>
			<v-btn to="/formel/" text>Formel</v-btn>
			
		</v-toolbar-items>
		
		<!--
		<v-switch hide-details
			v-model="$vuetify.theme.dark"
			class="px-5"
		></v-switch>
		-->
		
	
		<v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

	  
		
		 
    </v-app-bar>
		 
	  
	

	</div>
	
</template>


<script>
	export default {
		data: () => ({
		drawer: null,
		}),
	
		created () {
			this.$vuetify.theme.dark = false
		},
  }
</script>
