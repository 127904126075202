import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = () => ({

  cookieStatus: '',
  
});

const mutations = {
	
	setCookieStatus (state, cookieStatus) {
		state.cookieStatus = cookieStatus;
	},
	
};


const getters = {
	getCookieStatus: state => {
		return state.cookieStatus;
	},
};
	
	
export default new Vuex.Store({
	state: state,
	mutations: mutations,
	getters: getters
})