<template>

  <vue-cookie-accept-decline
    :ref="'myPanel1'"
    :elementId="'myPanel1'"
    :debug="false"
    :position="'bottom'"
    :type="'bar'"
    :disableDecline="false"
    :transitionName="'slideFromBottom'"
    @status="cookieStatus"
    @clicked-accept="cookieClickedAccept"
    @clicked-decline="cookieClickedDecline"
    @removed-cookie="cookieRemovedCookie"
  >

    <!-- Optional -->
    <div slot="message">
      Wir und ausgewählte Partner verwenden Cookies und andere Technologien, wie in der
      <a href="/datenschutzerklaerung/">Datenschutzerklaerung</a>
      angegeben um Ihnen z. B. personalisierte Inhalte anzuzeigen. Sie können der Verwendung solcher Technologien zustimmen, indem Sie diesen Hinweis akzeptieren. Weitere Informationen und Ihre persönlichen Tracking-Einstellungen finden Sie unter
      <a
        href="#"
        v-on:click="openConsentManager()"
      >Cookie-Einstellungen.</a>
    </div>

    <!-- Optional -->
    <div slot="declineContent">
      ABLEHNEN
    </div>

    <!-- Optional -->
    <div slot="acceptContent">
      AKZEPTIEREN
    </div>

  </vue-cookie-accept-decline>
</template>

/************************************************/
/* SCRIPT */
/************************************************/

<script>

import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import { EventBus } from '@/bus/bus.js';


export default {

	components: {
		VueCookieAcceptDecline
	},

	name: 'CookiesComp',
	data () {
		
		return {
			
			purpose: [],
			cookies: [],
			statusIntern: null,
			allPurposes: 
				[
				{id:'1', 
					name:'Anzeigen oder Inhalte technisch bereitstellen', 
					optin:false},
					
				{id:'2', 
					name:'Inhalte-Leistung messen', 
					optin:true}					
					],
			allCookies: 
				[
				{id:'1', 
					name:'Google Analytics', 
					belongsTo: ['2'],
					},
				{id:'2', 
					name:'Google Tag Manager', 
					belongsTo: ['1'],
					},
          ],
					
		};
	},
	
	
	created() {
		
		EventBus.$on('showCookieBanner', data => {
			this.$refs.myPanel1.isOpen = true;
		});
		
	},
	

	methods: {

		openConsentManager () {
			this.$refs.myPanel1.isOpen = false;
			EventBus.$emit('showCookieCompDialog');
		},
		

    cookieStatus (st) {
      this.status = st;
    },

    cookieClickedAccept () {
      this.acceptAll();
      this.status = 'accept';
    },

    cookieClickedDecline () {
      this.declineAll();
      this.status = 'decline';
    },

    cookieRemovedCookie () {
      this.status = null;
      this.$refs.myPanel1.init();
    },

    declineAll: function () {

		this.purposes = [];
		this.cookies = [];


      // always add the the necessary purposes and cookies
      for (var c = 0; c < this.allCookies.length; c++) {
        for (var t = 0; t < this.allCookies[c].belongsTo.length; t++) {
          if (!this.getPurpose(this.allCookies[c].belongsTo[t]).optin) {
			  
            var necessaryPurposeId = this.allCookies[c].belongsTo[t];

			
            if (!this.purposes.includes(necessaryPurposeId)) {
              this.purposes.push(necessaryPurposeId);
            }
            var necessaryCookies = this.getRelatedCookies(necessaryPurposeId);
            for (var nc of necessaryCookies) {
              if (!this.cookies.includes(nc.name)) {
                this.cookies.push(nc.name);
              }
            }
          }
        }
      }

      if (typeof localStorage !== 'undefined') {
        localStorage.setItem('purposesStatus', JSON.stringify(this.purposes));
        localStorage.setItem('activatedCookies', JSON.stringify(this.cookies));
        localStorage.setItem('allCookiesStatus', 'false');
      }
    },

    acceptAll: function () {

		this.purposes = [];
		this.cookies = [];

      for (var p of this.allPurposes) {
        for (var c of this.getRelatedCookies(p.id)) {
          if (!this.purposes.includes(p.id)) {
            this.purposes.push(p.id);
          }
          if (!this.cookies.includes(c.name)) {
            this.cookies.push(c.name);
          }
        }
      }
	  
	  
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem('purposesStatus', JSON.stringify(this.purposes));
        localStorage.setItem('activatedCookies', JSON.stringify(this.cookies));
        localStorage.setItem('allCookiesStatus', 'true');
      }
    },
    accept: function () {
      this.status = 'accept';
    },
	
	getPurpose: function (purposeId) {

		for (var p of this.allPurposes) {
          if (p.id == purposeId) {
			  return p;
			}
		}
		
		return null;

	},
	
	

    /**
    * Get related cookies as list
    *
    * @param purposeId
    * @return {Array} relatedCookies
    */
    getRelatedCookies: function (purposeId) {
		
      var relatedCookies = [];
      for (var c = 0; c < this.allCookies.length; c++) {
        for (var t = 0; t < this.allCookies[c].belongsTo.length; t++) {
          if (this.allCookies[c].belongsTo[t] == purposeId) {
            relatedCookies.push(this.allCookies[c]);
            break;
          }
        }
      }
	  
      return relatedCookies;
    },

  },

  computed: {
    statusText () {
		return this.status || 'No cookie set';
    },

    status: {
      // getter
      get: function () {
        return this.$refs.myPanel1.getCookieStatus();
      },
      // setter
      set: function (status) {
        this.$refs.myPanel1.setCookieStatus(status);
        this.$store.commit('setCookieStatus', status); // write also into store, cause localstorage is not reactive

		// push active cookies as event
		for (var c = 0; c < this.cookies.length; c++) {
			var eventName = "load-"+this.cookies[c].replace(/ /g,'');
			this.$gtm.trackEvent({event: eventName});
		}

      }
    }

  }
};

</script>



/************************************************/
/* STYLE */
/************************************************/

<style>
.cookie__bar {
  -ms-overflow-style: none;
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 9999;
  width: 100%;
  background: #eee;
  padding: 20px 20px;
  align-items: center;
  box-shadow: 0 -4px 4px rgba(198, 198, 198, 0.05);
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.cookie__bar--bottom {
  bottom: 0;
  left: 0;
  right: 0;
}
.cookie__bar--top {
  top: 0;
  left: 0;
  right: 0;
}
.cookie__bar__wrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media (min-width: 768px) {
  .cookie__bar__wrap {
    flex-direction: row;
  }
}
.cookie__bar__postpone-button {
  margin-right: auto;
  -ms-flex: 1 1 auto;
}
@media (min-width: 768px) {
  .cookie__bar__postpone-button {
    margin-right: 10px;
  }
}
.cookie__bar__postpone-button:hover {
  opacity: 0.8;
  cursor: pointer;
}
.cookie__bar__content {
  margin-right: 0;
  margin-bottom: 20px;
  font-size: 0.9rem;
  max-height: 103px;
  overflow: auto;
  width: 100%;
  -ms-flex: 1 1 auto;
}
@media (min-width: 768px) {
  .cookie__bar__content {
    margin-right: auto;
    margin-bottom: 0;
  }
}
.cookie__bar__buttons {
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 768px) {
  .cookie__bar__buttons {
    flex-direction: row;
    width: auto;
  }
}
.cookie__bar__buttons__button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 3px;
  font-size: 0.9rem;
}
.cookie__bar__buttons__button:hover {
  cursor: pointer;
  text-decoration: none;
}
.cookie__bar__buttons__button--accept {
  -ms-flex: 1 1 auto;
  background: var(--v-secondary-base);
  color: #fff;
  border-radius: 0;
}
.cookie__bar__buttons__button--accept:hover {
  background: var(--v-primary-lighten1);
}
.cookie__bar__buttons__button--decline {
  -ms-flex: 1 1 auto;
  color: #000;
  margin-bottom: 10px;
  border-radius: 0;
  border-color: #000;
}
.cookie__bar__buttons__button--decline:hover {
  background: #fff;
}
@media (min-width: 768px) {
  .cookie__bar__buttons__button--decline {
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.slideFromBottom-enter,
.slideFromBottom-leave-to {
  transform: translate(0px, 10em);
}
.slideFromBottom-enter-to,
.slideFromBottom-leave {
  transform: translate(0px, 0px);
}
.slideFromBottom-enter-active {
  transition: transform 0.2s ease-out;
}
.slideFromBottom-leave-active {
  transition: transform 0.2s ease-in;
}
.slideFromTop-enter,
.slideFromTop-leave-to {
  transform: translate(0px, -10em);
}
.slideFromTop-enter-to,
.slideFromTop-leave {
  transform: translate(0px, 0px);
}
.slideFromTop-enter-active {
  transition: transform 0.2s ease-out;
}
.slideFromTop-leave-active {
  transition: transform 0.2s ease-in;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
