<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-dialog
      v-model="showDialog"
      max-width="1260"
      persistent
    >
      <v-card>
        <v-card-title class="stickyCardTitle">Cookie-Einstellungen</v-card-title>
        <v-card-text>
          <CookieConsentManager
            ref="CookieConsentManager"
            @closeDialog="hide()"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style lang="scss" scoped>
.stickyCardTitle {
  position: sticky !important;
  top: 0 !important;
  left: 0 !important;
  background-color: #e0e0e0 !important;
  z-index: 2 !important;
}
</style>

<script>

import CookieConsentManager from '@/components/CookieConsentManager.vue';
import { EventBus } from '@/bus/bus.js';

export default {
  components: {
    CookieConsentManager
  },
  computed: {

    cookieStatus: {
      // getter
      get: function () {
        this.$logger.log('Cookies.vue get cookieStatus = ' + this.$store.getters.getCookieStatus);
        return this.$store.getters.getCookieStatus;
      }
    }
  },
  
    
	created() {
		
		EventBus.$on('showCookieCompDialog', data => {
			this.show()
		});
	},
  
  data () {
    return {
      showDialog: false,
    }
  },
  mounted () {
	  
    // Close modal with 'esc' key
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 27) {
        // escape was pressed
        // decline all cookies
      //  this.$refs.cookieConsentManager.cancel();
        // close dialog
        this.hide();
        // show cookie banner if nothing is set    
        // show cookie banner if nothing is set  
        if (typeof localStorage !== 'undefined') {
          if (localStorage.getItem('vue-cookie-accept-decline-myPanel1') == null) {
            //$("#myPanel1").show();
			EventBus.$emit('showCookieBanner');
          }
        }
      } 
    });
  },
  methods: {
    show () {
      // initialize status of cookie consens manager from local storage
      if (this.$refs.cookieConsentManager) {
        this.$refs.cookieConsentManager.init(true);
      }
      this.showDialog = true;
    },
    hide () {
      this.showDialog = false;
    }
  }
}
</script>