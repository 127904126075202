import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)


	const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/berechnen',
		name: 'Berechnen',
		component: function () {
			return import('../views/Berechnen.vue')
		}
	},
	{
		path: '/formel',
		name: 'Formel',
		component: function () {
			return import('../views/Formel.vue')
		}
	},
	{
		path: '/beispiele',
		name: 'Beispiele',
		component: function () {
			return import('../views/Beispiele.vue')
		}
	},
	{
		path: '/impressum',
		name: 'Impressum',
		component: function () {
			return import('../views/Impressum.vue')
		}
	},
	{
		path: '/datenschutzerklaerung',
		name: 'Datenschutzerklärung',
		component: function () {
			return import('../views/Datenschutzerklaerung.vue')
		}
	},
]



const router = new VueRouter({
	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0 }
	},
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router



