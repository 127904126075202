<template>
<v-app id="main">

    <MenuBar />

    <v-main>
        <router-view />
    </v-main>

    <Footer />

    <CookiesComp ref="cookiesComp"></CookiesComp>
    <CookiesCompDialog ref="cookiesCompDialog"></CookiesCompDialog>

</v-app>
</template>

/************************************************/
/* SCRIPT */
/************************************************/

<script>
import MenuBar from '@/components/MenuBar.vue';
import CookiesComp from '@/components/CookiesComp.vue';
import CookiesCompDialog from '@/components/CookiesCompDialog.vue';
import Footer from '@/components/Footer.vue';

export default {
    components: {
        MenuBar,
        CookiesComp,
        CookiesCompDialog,
        Footer,
    },


    metaInfo() {
        return {
            htmlAttrs: {
                lang: 'de'
            },
            meta: [{
                    name: 'robots',
                    content: 'index,follow'
                },
                {
                    name: 'google-site-verification',
                    content: 'yb51QJ4It2hYFdh9BgwFHxJZaW3GkbuMzmD8Qzhkq3A'
                },
            ]
        };
    },

};
</script>
